#homepage {
  width: 100%;

  .red-section {
    padding: 80px 0px;
    background-color: #b10c0e;
    color: white;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
      color: white;
    }

    .ant-statistic-content-value {
      font-family: 'Montserrat Bold';
      font-size: 45px;
      margin-top: 0.5em;
    }

    #auction-btn {
      background: #3ca44b;
      border-color: #3ca44b;
      border-radius: 10px;
      box-shadow: 0px 0px 5px #000;
      transition: all 0.2s;
      height: 60px;
      padding-top: 6.4px !important;
      line-height: 45px !important;

      &:hover {
        filter: brightness(1.1);
        transform: scale(1.05);
        box-shadow: 3px 3px 15px #000;
      }

      span {
        font-weight: 600;
      }
    }
  }

  .white-section {
    padding: 80px 0px;
    background-color: #fff;
    color: #23286b;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
      color: #23286b;
    }
  }

  #auction {
    text-align: center;

    h2 {
      margin: 10px;
    }
  }

  .content {
    width: 70%;
    margin: 0 auto;
    text-align: justify;

    h1 {
      font-size: 60px;
      font-weight: 700;
    }

    h2 {
      font-size: 40px;
      font-weight: 600;
    }

    h3 {
      font-size: 24px;
      font-weight: 600;
    }

    p,
    li {
      font-size: 16px;
      font-weight: 400;
    }

    &#how-to-help {
      .ant-col:nth-child(1) {
        padding-right: 30px;
      }

      .ant-col:nth-child(2) {
        padding-left: 30px;
      }
    }
  }

  #projects {
    display: flex;
    flex-direction: column;

    &>div {
      display: flex;
      align-items: center;

      img {
        height: 100%;
        width: 50%;

        &.right-side {
          margin-left: 20px;
        }

        &.left-side {
          margin-right: 20px;
        }
      }

      .ant-col:nth-child(1) {
        align-self: baseline;
        padding-right: 20px;
      }

      .ant-col:nth-child(2) {
        align-self: baseline;
        padding-left: 20px;
      }
    }

    .special-link {
      color: #e57200 !important;
    }

  }

  #poster {
    width: 100%;
    border: 2px solid #000;
    box-shadow: 0px 0px 15px #000;
    transition: all 0.2s;

    &:hover {
      transform: scale(1.05);
      filter: brightness(1.05);
      box-shadow: 5px 5px 30px #000;
    }
  }
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 55px !important;
  }

  #how-to-help {
    .ant-col {
      padding: 0 !important;
    }
  }

  #projects {
    word-break: break-word;

    img {
      display: none;
    }

    .ant-col {
      padding: 0 !important;
    }
  }
}

/* 
h1 {
  margin-block-start: 20px;
  margin-block-end: 10px;
}

h2 {
  margin-block-start: 45px;
  margin-block-end: 5px;
}
 */
/* li {
  margin-block-end: 5px;
}

ul {
  padding-inline-start: 35px;
}


#Leilao_btn , #colaboracao{
  font-size: 18px;
  margin-top: 45px;
  text-align: center;
}

.subtitle {
  color: #23286b;
  font-size: 2.4em;
}

#grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  justify-items: stretch;
  align-content: stretch;
  justify-content: stretch;
  column-gap: 60px;
  row-gap: 40px;
}

.card {
  font-size: 18px;
}

.card_title {
  font-weight: 700;
  font-size: 24px;
}



#cartaz1 {
  height: 60%;
  width: 70%;
}

.project_description {
  font-size: 18px;
}

.project_title {
  color: #23286b;
  font-weight: 700;
  font-size: 2.4em;
} */