#backoffice {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}


.ant-carousel {
    width: 400px;
    height: 400px;
    border: 2px solid #000;
    box-shadow: 0px 0px 4px #000;
    transition: all 0.3s;

    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide div {
        height: 100%;
    }

    &:hover {
        transform: scale(1.03);
        filter: brightness(1.1);
        box-shadow: 0px 0px 10px #000;
    }

    .product-image {
        height: 100%;
        width: 100%;
        transition: all 0.2s;
        cursor: pointer;
    }

    .slick-dots {

        li:not(.slick-active) button,
        .slick-active button {
            background-color: #fff;
            box-shadow: 0px 0px 5px #000;
        }
    }

}

.seller-image {
    height: 120px;
    width: 120px;
}

.product-bio {
    margin-top: 10px;
    font-weight: bold;
}

.info-wrapper {
    height: 270px;
    width: 270px;
    background-color: white;
    border: 1px solid rgb(163, 163, 163);
    border-radius: 7px;
    box-shadow: inset 0 0 4px #000000;
    padding: 58px 20px;
}

.selector {
    display: flex;
    flex-direction: column;

    &>* {
        margin-top: 10px;
    }

    .ant-input-number {
        width: 100%;
        border: 1px solid black;
    }
}

/* Three image containers (use 25% for four, and 50% for two, etc) */
.column {
    float: left;
    width: 50%;
    padding: 15px;
    text-align: center;
    justify-content: center;
}

/* Clear floats after image containers */
.row::after {
    content: "";
    clear: both;
    display: table;
}

.price-info {
    text-align: center;
}

.info {
    position: relative;
    top: 5%;
    left: 0%;
}