#auction {
  .ant-row {
    margin: 0px;

    &:nth-child(odd) {
      background-color: #b10c0e;
      color: #fff;
    }

    &>div>div {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 10px;

      .ant-carousel {
        width: 400px;
        height: 400px;
        border: 2px solid #000;
        box-shadow: 0px 0px 4px #000;
        transition: all 0.3s;

        .slick-slider,
        .slick-list,
        .slick-track,
        .slick-slide,
        .slick-slide div {
          height: 100%;
        }

        &:hover {
          transform: scale(1.03);
          filter: brightness(1.1);
          box-shadow: 0px 0px 10px #000;
        }

        .product-image {
          height: 100%;
          width: 100%;
          transition: all 0.2s;
          cursor: pointer;
        }

        .slick-dots {

          li:not(.slick-active) button,
          .slick-active button {
            background-color: #fff;
            box-shadow: 0px 0px 5px #000;
          }
        }

      }

      .product-bio {
        margin-top: 10px;
        font-weight: bold;
        width: 400px;
        height: 50px;
      }

      .seller-info {
        width: 400px;
      }



      .pricing {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .auction-btn-wrapper {
        align-self: center;
      }

      .auction-btn {
        background: #3ca44b;
        border-color: #3ca44b;
        border-radius: 10px;
        box-shadow: 0px 0px 5px #000;
        transition: all 0.2s;
        height: 60px;
        padding-top: 6.4px !important;
        line-height: 45px;

        &[disabled] {
          background: #979797 !important;
          border-color: #646464 !important;
          color: #c5c5c5;
        }

        &:hover:enabled {
          filter: brightness(1.1);
          transform: scale(1.05);
          box-shadow: 3px 3px 15px #000;
        }

        span {
          font-weight: 600;
        }

        .money-check {
          margin-left: 10px;
          border-radius: 10px;
        }
      }

    }
  }
}

.ant-modal-body>p {
  padding-right: 30px;
  text-align: justify;
}

.seller-image {
  height: auto;
  width: 100%;
  border: 2px solid #000;
  box-shadow: 0px 0px 4px #000;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.03);
    filter: brightness(1.1);
    box-shadow: 0px 0px 10px #000;
  }
}

.seller-image-override {
  height: 300px !important;
  width: 300px !important;
  cursor: default;
}

#donations {
  margin: 40px 0px;
  text-align: center;

  * {
    color: #23286b;
  }

  &>h2 {
    font-family: 'Montserrat Bold';
    font-weight: 700;
    font-size: 35px;
  }


}


@media only screen and (max-width: 768px) {
  .ant-carousel {
    width: 80% !important;
    height: 80% !important;
  }

  .product-bio {
    width: 80% !important;
  }

  .seller-info {
    margin-top: 10px !important;
    width: 100% !important;
  }

  .seller-image {
    height: 80px !important;
    width: 80px !important;
  }

  .seller-image-override {
    height: 300px !important;
    width: 300px !important;
  }

  .auction-btn-wrapper>a {
    font-size: 12px;
    line-height: 30px !important;
    height: 45px !important;
  }
}