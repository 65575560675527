@font-face {
  font-family: 'Montserrat Black';
  src: url("../fonts/Montserrat-Black.ttf") format("opentype");
}

@font-face {
  font-family: 'Montserrat Black Italic';
  src: url("../fonts/Montserrat-BlackItalic.ttf") format("opentype");
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url("../fonts/Montserrat-Bold.ttf") format("opentype");
}

@font-face {
  font-family: 'Montserrat Bold Italic';
  src: url("../fonts/Montserrat-BoldItalic.ttf") format("opentype");
}

@font-face {
  font-family: 'Montserrat Extra Bold';
  src: url("../fonts/Montserrat-ExtraBold.ttf") format("opentype");
}

@font-face {
  font-family: 'Montserrat Extra Bold Italic';
  src: url("../fonts/Montserrat-ExtraBoldItalic.ttf") format("opentype");
}

@font-face {
  font-family: 'Montserrat Extra Light';
  src: url("../fonts/Montserrat-ExtraLight.ttf") format("opentype");
}

@font-face {
  font-family: 'Montserrat Extra Light Italic';
  src: url("../fonts/Montserrat-Italic.ttf") format("opentype");
}

@font-face {
  font-family: 'Montserrat Italic';
  src: url("../fonts/Montserrat-Italic.ttf") format("opentype");
}

@font-face {
  font-family: 'Montserrat Light';
  src: url("../fonts/Montserrat-Light.ttf") format("opentype");
}

@font-face {
  font-family: 'Montserrat Light Italic';
  src: url("../fonts/Montserrat-LightItalic.ttf") format("opentype");
}

@font-face {
  font-family: 'Montserrat Medium';
  src: url("../fonts/Montserrat-Medium.ttf") format("opentype");
}

@font-face {
  font-family: 'Montserrat Medium Italic';
  src: url("../fonts/Montserrat-MediumItalic.ttf") format("opentype");
}

@font-face {
  font-family: 'Montserrat Regular';
  src: url("../fonts/Montserrat-Regular.ttf") format("opentype");
}

@font-face {
  font-family: 'Montserrat Semi Bold';
  src: url("../fonts/Montserrat-SemiBold.ttf") format("opentype");
}

@font-face {
  font-family: 'Montserrat Semi Bold Italic';
  src: url("../fonts/Montserrat-SemiBoldItalic.ttf") format("opentype");
}

@font-face {
  font-family: 'Montserrat Thin';
  src: url("../fonts/Montserrat-Thin.ttf") format("opentype");
}

@font-face {
  font-family: 'Montserrat Thin Italic';
  src: url("../fonts/Montserrat-ThinItalic.ttf") format("opentype");
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}