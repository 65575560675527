@import '~antd/dist/antd.css';

#header-wrapper {
    position: fixed;
    top: 0;
    z-index: 1002;
    width: 100vw;
    height: 90px;
    background-color: #fff;
    border-bottom: 2px solid #000;
    box-shadow: 0px 0px 15px #000;
    padding: 5px 0px;

    #header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 70%;
        height: 100%;
        margin: 0 auto;

        &>a {
            height: 100%;

            #ajudaris-logo {
                height: 100%;
                transition: 0.1s all;

                &:hover {
                    opacity: 0.7;
                    cursor: pointer;
                }
            }
        }

        #navbar,
        #mobile-navbar {

            a {
                margin: 15px;
                color: #000;
                font-size: 18px;
                font-weight: bold;

                &:hover {
                    background: #39a348;
                    background: linear-gradient(90deg, #39a348 0%, #bfc615 50%, #ee2b24 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }

        #mobile-navbar {
            display: none;
        }
    }
}

@media only screen and (max-width: 1200px) {
    #navbar {
        display: none;
    }

    #mobile-navbar {
        display: block !important;

        #mobile-menu-btn {
            font-size: 36px;
            color: #23286b;
            cursor: pointer;
            transition: 0.3s all;

            &:hover {
                transform: scale(1.2);
                filter: drop-shadow(0px 0px 5px #979efd)
            }
        }
    }
}

.ant-dropdown {
    a {
        font-size: 16px;
        font-weight: 600;
        color: #23286b;

        &:hover {
            color: #4d83ca !important;
        }
    }
}