@import '~antd/dist/antd.css';

#login {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #bababa;
}

.login-form-wrapper {
    width: 100%;
    text-align: center;
}

.login-form {
    height: 270px;
    width: 270px;
    background-color: white;
    border: 1px solid rgb(163, 163, 163);
    border-radius: 7px;
    box-shadow: inset 0 0 4px #000000;
    padding: 58px 20px;
}