#footer-wrapper {
    width: 100%;
    border-top: 2px solid #000;

    #footer {
        width: 70%;
        margin: 0 auto;
        padding: 10px 0px;
        color: #23286b;

        .ant-col {
            text-align: center;
            h2 {
                color: #23286b;
            }

            &:nth-child(1) {
                align-self: center;
            }

            svg {
                height: 40px;
                width: 40px;
                margin-right: 20px;
                filter: drop-shadow(2px 2px 3px #9e9e9e);
                color: #414141;
                transition: all 0.3s;

                &#li-btn {
                    margin-right: 0px;
                }

                &:hover {
                    transform: scale(1.2);
                    filter: drop-shadow(3px 3px 5px #e4e4e4);

                    &#fb-btn {
                        color: #4267b2;
                    }
    
                    &#yt-btn {
                        color: #ff0000;
                    }

                    &#ig-btn {
                        color: #e1306c;
                    }

                    &#li-btn {
                        color: #2867b2;
                    }
                }

            }
        }
    }
}